import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-4">
                <p>
                    Prepara't per triomfar a les oposicions amb una formació intensiva, renovada i
                    totalment actualitzada!
                </p>

                <p>
                    📅 <strong>Divendres, 7 de febrer de 2025</strong>
                    <br />
                    🕕 <strong>De 18.00 a 22.00h</strong>
                    <br />
                    🌐 <strong>En directe per Zoom</strong>
                </p>

                <p>
                    <strong>Accés flexible</strong>: La sessió queda gravada i disponible al campus
                    fins al <strong>15 de febrer</strong>, així podràs repassar o veure-la si no
                    pots assistir en directe.
                </p>

                <p>
                    <strong>🔑 Què aprendràs?</strong>
                </p>
                <ul>
                    <li>
                        Un repàs intensiu dels conceptes més importants i recurrents a l'examen
                        d'aquest àmbit, dissenyat per maximitzar les teves opcions d'èxit.
                    </li>
                </ul>

                <p>
                    <strong>
                        💳 Reserva la teva plaça ara i prepara’t per donar el millor de tu mateix en
                        les oposicions!
                    </strong>
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/I5mUSaRUu1c"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per superar les oposicions de Mossos d'Esquadra amb les nostres MÀSTER CLASS intensives, actualitzades i dissenyades per triomfar a l’examen del 15 de febrer 2025. Aprèn els conceptes clau en directe i accedeix a les gravacions fins al dia de la prova"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
